import React from 'react';
import { Box, Typography, Button, TextField, Container, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { EstadoProveedor } from '../types/EstadoProveedor';

type EstadoNormasProps = {
  data: EstadoProveedor;
}

const EstadoNormas: React.FC<EstadoNormasProps> = ({data}) => {
  return (
        <Box maxWidth="md">       
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Norma Certificada</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(['Ternium', 'Tenaris', 'FINMA'] as const).map((empresa) => 
                <TableRow key={empresa}>
                  <TableCell>{empresa}</TableCell>
                  <TableCell>{data[empresa]?.certifico}</TableCell>
                  <TableCell align='right'>{!!data[empresa]?.vigente ? <CheckCircle sx={{ color: 'green' }} /> : <Cancel sx={{ color: 'red' }} />}</TableCell>
                  <TableCell>{!!data[empresa]?.vigente ? 'Certificado' : !!data[empresa]?.certifico ? 'No Vigente' : 'No Certificado'}</TableCell>
                </TableRow>
                )}                
              </TableBody>
            </Table>
            <Typography sx={{color: 'red', marginTop:2}}>Fecha de última certificación: {data.fecha}</Typography>      
        </Box> 
  );
};

export default EstadoNormas;
