import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Table, TableHead, TableRow, TableBody, TableCell, Tooltip, Button, CircularProgress } from '@mui/material';
import { CheckCircle, Cancel, Search as SearchIcon } from '@mui/icons-material';
import { Proveedor } from '../types/Proveedor';
import { Link, useNavigate } from "react-router-dom";
import { Search, SearchIconWrapper, StyledInputBase } from './Search';
import { apiUrl } from '../config';
import { User } from '../types/User';
import { getCurrentUser } from '../session';
import { formatCuit } from '../utils';

const Home: React.FC = () => {
  const empresas = ['Ternium', 'Tenaris', 'FINMA'] as const;
  const user = getCurrentUser();
  const navigate = useNavigate();
  const [data, setData] = useState<Proveedor[]>([]);
  const [searched, setSearched] = useState<string>("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearched(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);
  
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    async function fetchData() {
      try {
        if (!user) {
          navigate("/login");
        }

        // Make a GET request when the component is mounted
        const response = await fetch(apiUrl + 'proveedores.php');

        if (!response.ok) {
          navigate("/login");
        }

        const responseData = await response.json();

        // Update the component state with the fetched data
        setData(responseData);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, []); // The empty dependency array ensures it runs only once.


  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%', flexDirection:'row' }}>
        <Typography component="h2" variant="h5" color="primary" gutterBottom>
          Listado de Proveedores
        </Typography>
        <Box maxWidth="md" style={{marginLeft:'auto' }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{width: '100%'}}
              placeholder="Buscar…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={debouncedResults}
              />
          </Search>
        </Box>
        {user?.type === 'ADMIN' ?
          <Link to={'/proveedor'}><Button style={{marginLeft: 5 }} variant="contained" color="primary">Nuevo Proveedor</Button></Link>
          : <span></span>}
      </Box>
      { data.length ? 
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Proveedor</TableCell>
            <TableCell>CUIT</TableCell>
            <TableCell>Expediente</TableCell>
            <TableCell colSpan={empresas.length}>Normas Certificadas</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(searched ? data.filter((row) => 
            row.nombre.toLowerCase().includes(searched.toLowerCase()) || (searched.replace(/\D/g,'') && row.cuit.includes(searched.replace(/\D/g,'')))) 
            : data
            ).map((p) => (
            <TableRow key={p.id_proveedor}>
              <TableCell>{p.nombre}</TableCell>
              <TableCell sx={{whiteSpace: 'nowrap'}}>{formatCuit(p.cuit)}</TableCell>
              <TableCell>{p.carpeta}</TableCell>
              {(empresas).map((empresa) => 
                <TableCell>
                  {empresa} <Tooltip title={p[empresa]?.certifico ? (!!p[empresa]?.vigente ? 'Norma vigente: ' : 'No vigente: ') + p[empresa]?.certifico : 'No certificado'}>{!!p[empresa]?.vigente ? <CheckCircle sx={{ color: 'green' }} /> : <Cancel sx={{ color: 'red' }} />}</Tooltip>
                </TableCell>
                )}
              <TableCell align="right">
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {user?.type === 'ADMIN' ?
                    <Link to={`/proveedor/${p.id_proveedor}`} style={{marginRight: 5}}><Button variant="outlined">Editar</Button></Link>
                    : <span></span>
                  }
                  <Link to={`/estado/${p.id_proveedor}`}><Button variant="outlined" sx={{whiteSpace: 'nowrap'}}>Mas Info</Button></Link>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      :       
      <Box sx={{ display: 'flex', flexDirection: 'column',  alignItems: 'center' }}>
        <CircularProgress size="5rem"/>
      </Box>
      }
    </Box>
  );
};

export default Home;
