import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LogoBlack from '../logo-blanco.png';

export default function Footer() {
  return (
      <footer style={{ marginTop: "auto", backgroundColor: 'black', padding: 40}}>
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Container sx={{marginRight:0, marginTop:2}}>
                <Typography variant="body1" color="white" align="right">
                    Todos los derechos reservados
                </Typography>
                <Typography variant="body2" color="white" align="right">
                    {"Copyright © "}
                    <Link color="inherit" href="http://surcompliance.com.ar/">
                        Surcompliance
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {"."}
                </Typography>
            </Container>
            <img src={LogoBlack} alt="Logo" style={{height: '80px'}} />
        </Box>
      </footer>
  );
}
