import React from 'react';
import { ThemeProvider, createTheme, Box } from '@mui/material';
import { CssBaseline, Container } from '@mui/material';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contacto from './components/Contacto';
import Login from './components/Login';
import Footer from './components/Footer';
import Proveedores from './components/Proveedores';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import Consultar from './components/Consultar';
import EstadoProveedor from './components/EstadoProveedor';
import Proveedor from './components/Proveedor';
import Documentacion from './components/Documentacion';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2', // Adjust this color to your primary color
    },
    secondary: {
      main: '#FF4081', // Adjust this color to your secondary color
    },
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ResponsiveAppBar />
      <section className="heroimg" style={{ backgroundImage: `url('img/header-control-proveedores.jpeg')` }} />
      <Container maxWidth="lg" sx={{ marginTop:5, marginBottom:10  }}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/proveedores" element={<Proveedores />} />
            <Route path="/proveedor/:id_proveedor/documentacion/:id_documentacion" element={<Documentacion />} />
            <Route path="/proveedor/:id_proveedor/documentacion" element={<Documentacion />} />
            <Route path="/proveedor/:id_proveedor" element={<Proveedor />} />
            <Route path="/proveedor" element={<Proveedor />} />
            <Route path="/consultar" element={<Consultar />} />
            <Route path="/estado/:id_proveedor" element={<EstadoProveedor />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Router>
      </Container>
      <Footer></Footer>
    </ThemeProvider>
  );
}

export default App;
