import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Container, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import InputMask from 'react-input-mask';
import { ConsultaProveedor } from '../types/Proveedor';
import EstadoNormas from './EstadoNormas';
import { apiUrl } from '../config';
import { formatCuit } from '../utils';

const Consultar: React.FC = () => {
  const [cuit, setCuit] = useState('');
  const [email, setEmail] = useState('');
  const [data, setData] = useState(undefined as ConsultaProveedor | undefined);
  const [mensaje, setMensaje] = useState('');

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    // Create a FormData object to send as the request body
    const formData = new FormData();
    formData.append('cuit', cuit);
    formData.append('email', email);

    try {
      const response = await fetch(apiUrl + 'consulta.php', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      if (response.ok) {
        // Handle a successful login here, e.g., redirect or display a success message
        console.log('Request ok!');
        setMensaje('');
        setData(await response.json());
      } else {
        // Handle login failure, e.g., display an error message
        console.error('Request failed');
        setMensaje('No se pudo encontrar la informacion solicitada.');
        setData(undefined);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setMensaje('Ocurrio un error, por favor consulte telefonicamente.')
      setData(undefined);
    }
  };
  return (
    <Box>
    <Box maxWidth="sm">
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        Consulte su estado de certificación
      </Typography>
      <form>        
        <InputMask
          mask="99-99999999-9"
          value={cuit}
          onChange={(e) => setCuit(e.target.value?.replace(/\D/g,''))}
          disabled={false}
          maskPlaceholder=" "
          >
          <TextField
            label="CUIT"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </InputMask>
        <TextField
          label="e-mail"
          variant="outlined"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Consultar
        </Button>
      </form>
    </Box>

      { data ?
        <Box maxWidth="md" sx={{marginTop: 10}}>       
            <Typography component="h2" variant="h5" color="primary" gutterBottom>{data.nombre} ({formatCuit(data.cuit)})</Typography>
            <EstadoNormas data={data} />    
        </Box> 
        : 
        <Typography sx={{color: 'red', marginTop:2}}>{mensaje}</Typography>     
      }
    </Box>
  );
};

export default Consultar;
