import React, { useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../config';

const Login: React.FC = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    // Create a FormData object to send as the request body
    const formData = new FormData();
    formData.append('user', username);
    formData.append('pass', password);

    try {
      const response = await fetch(apiUrl + 'login.php', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      if (response.ok) {
        // Handle a successful login here, e.g., redirect or display a success message
        console.log('Login ok!');
        const userData = await response.json();
        window.sessionStorage.setItem("user", JSON.stringify(userData));

        navigate("/proveedores");
      } else {
        // Handle login failure, e.g., display an error message
        console.error('Login failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  return (
    <Box maxWidth="sm">
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        Ingresar
      </Typography>
      <form>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </form>
    </Box>
  );
};

export default Login;
