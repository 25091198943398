import React from 'react';
import { Box, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Contacto: React.FC = () => {
  return (
    <Box>
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        Contáctenos
      </Typography>
      <Typography variant="body1">
        A continuación, se detallan los distintos canales de atención puestos a disposición de los proveedores con fin de agilizar la gestión de la certificación notarial de las normas:<br/>
        <br/>
        <u>Horario de atención:</u> Lunes a Viernes de 10:00 hs a 16:00 hs.
        <ul>
          <li><b>Teléfonos:</b> +54 11 4742-6432/7568 </li>
          <li><b>Email:</b> info@surcompliance.com.ar </li>
          <li><b>Guardia Telefónica:</b> <WhatsAppIcon sx={{ color: 'green', fontSize: 18 }} /> +54 9 11 3408-4546</li>
          <li><b>Oficinas:</b> Cosme Beccar 168 - San Isidro (CP 1642) - Provincia de Buenos Aires. </li>
        </ul>
        <br/>
        <u>Red de Escribanías:</u>
        <ul>          
          <li>Gobierno Autónomo de la Ciudad de Bs As (C.A.B.A.)</li>
          <li>Provincia de Buenos Aires (San Nicolas)</li>
          <li>Provincia de Buenos Aires (San Isidro)</li>
          <li>Provincia de Buenos Aires (Bahía Blanca)</li>
          <li>Provincia de Córdoba</li>
          <li>Provincia de Mendoza</li>
          <li>Provincia de Neuquén</li>
          <li>Provincia de Salta</li>
          <li>Provincia de San Luis</li>
        </ul>
        Durante el proceso de certificación el Proveedor podrá verificar el avance del trámite en el siguiente <a href='#/consultar'>link</a>        
      </Typography>
    </Box>
  );
};

export default Contacto;
