import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem } from '@mui/material';
import InputMask from 'react-input-mask';
import { ProveedorData } from '../types/Proveedor';
import { apiUrl } from '../config';
import { Link, useNavigate, useParams } from 'react-router-dom';

const Proveedor: React.FC = () => {
  const navigate = useNavigate();
  const { id_proveedor } = useParams();
  const [data, setData] = useState<ProveedorData>({
    tipoactividad: 'CONTRATISTA',
    tipopersona: 'JURIDICA',
    estado: 'FINALIZADO',
    nombre: '',
    cuit: '',
    domicilio: '',
    localidad: '',
    codigopostal: '',
    provincia: '',
    telefono: '',
    mailproveedor: '',
  });
  const [mensaje, setMensaje] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        if (!id_proveedor) {
          return;
        }

        // Make a GET request when the component is mounted
        const response = await fetch(apiUrl + 'proveedor.php?id=' + id_proveedor);

        if (!response.ok) {
          navigate("/login");
        }

        // Update the component state with the fetched data
        setData(await response.json());
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, []); // The empty dependency array ensures it runs only once.


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!data?.nombre || !data.cuit) {
      setMensaje('Por favor complete los campos obligatorios (*)')
      return;
    }

    try {
      const response = await fetch(apiUrl + 'proveedor.php', {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data),
        credentials: 'include',
      });

      if (response.ok) {
        // Handle a successful login here, e.g., redirect or display a success message
        console.log('Request ok!');
        setMensaje('');
        const savedData = await response.json();
        navigate(`/estado/${savedData.id_proveedor}`);
      } else {
        // Handle login failure, e.g., display an error message
        console.error('Request failed');
        setMensaje('No se pudo encontrar la informacion solicitada.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setMensaje('Ocurrio un error, por favor consulte telefonicamente.')
    }
  };

  return (
    <Box>
    <Box maxWidth="sm">
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        Datos del Proveedor
      </Typography>
      <form>        
        <TextField
          label="Nombre o Razón Social *"
          variant="outlined"
          type="text"
          fullWidth
          margin="normal"
          value={data.nombre || ''}
          onChange={(e) => setData({ ...data, 'nombre': e.target.value})}
        />
        <InputMask
          mask="99-99999999-9"
          value={data.cuit || ''}
          onChange={(e) => setData({ ...data, 'cuit': e.target.value?.replace(/\D/g,'')})}
          disabled={false}
          maskPlaceholder=" "
          >
          <TextField
            label="CUIT *"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </InputMask>
        <TextField
          label="Domicilio"
          variant="outlined"
          type="text"
          fullWidth
          margin="normal"
          value={data.domicilio || ''}
          onChange={(e) => setData({ ...data, 'domicilio': e.target.value})}
        />
        <TextField
          label="Localidad"
          variant="outlined"
          type="text"
          fullWidth
          margin="normal"
          value={data.localidad || ''}
          onChange={(e) => setData({ ...data, 'localidad': e.target.value})}
        />        
        <Box style={{display: 'flex', flexDirection: 'row' }}>
          <TextField
            label="Provincia"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.provincia || ''}
            onChange={(e) => setData({ ...data, 'provincia': e.target.value})}
          />
          <TextField
            label="Código Postal"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.codigopostal || ''}
            onChange={(e) => setData({ ...data, 'codigopostal': e.target.value})}
            sx={{ width: 1/2, marginLeft:1 }}
          />
        </Box>
        <Box style={{display: 'flex', flexDirection: 'row' }}>
          <TextField
            label="Email"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.mailproveedor || ''}
            onChange={(e) => setData({ ...data, 'mailproveedor': e.target.value})}
          />
          <TextField
            label="Teléfono"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.telefono || ''}
            onChange={(e) => setData({ ...data, 'telefono': e.target.value})}
            sx={{ marginLeft:1 }}
          />
        </Box>
        <FormControl fullWidth>
          <FormLabel id="actividad-label">Tipo Actividad</FormLabel>
          <RadioGroup
            row
            aria-labelledby="actividad-label"
            value={data.tipoactividad}
            onChange={(e) => setData({ ...data, 'tipoactividad': e.target.value})}
          >
            <FormControlLabel value="CONTRATISTA" control={<Radio />} label="Contratista" />
            <FormControlLabel value="TRANSPORTISTA" control={<Radio />} label="Transportista" />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth>
          <FormLabel id="persona-label">Tipo Persona</FormLabel>
          <RadioGroup
            row
            aria-labelledby="persona-label"
            value={data.tipopersona}
            onChange={(e) => setData({ ...data, 'tipopersona': e.target.value})}
          >
            <FormControlLabel value="JURIDICA" control={<Radio />} label="Jurídica" />
            <FormControlLabel value="FISICA_MONOTRIBUTO" control={<Radio />} label="Física Monotributo" />
            <FormControlLabel value="FISICA_INSCRIPTO" control={<Radio />} label="Física Inscripto" />
          </RadioGroup>
        </FormControl>
        <Box style={{display: 'flex', flexDirection: 'row' }}>
          <FormControl fullWidth>
            <InputLabel id="estado-label">Estado</InputLabel>
            <Select            
              label="Estado"
              labelId="estado-label"
              value={data.estado}
              onChange={(e) => setData({ ...data, 'estado': e.target.value})}
            >
              <MenuItem value="CONTACTADO">CONTACTADO</MenuItem>
              <MenuItem value="EN TRAMITE">EN TRAMITE</MenuItem>
              <MenuItem value="EXCEPCION">EXCEPCION</MenuItem>
              <MenuItem value="FINALIZADO">FINALIZADO</MenuItem>
              <MenuItem value="INACTIVO">INACTIVO</MenuItem>
              <MenuItem value="NO CONTACTADO">NO CONTACTADO</MenuItem>
              <MenuItem value="TURNO">TURNO</MenuItem>
            </Select>          
          </FormControl>
          <TextField         
            disabled
            id="outlined-disabled"
            label="Nro de Carpeta"
            value={data.carpeta || ''}
            sx={{ width: 1/2, marginLeft:1 }}
          />
        </Box>
      </form>
      <Link to={'/proveedores'}><Button variant="outlined" style={{marginTop: 15, marginRight: 5 }}>Volver</Button></Link>
      <Button variant="contained" color="primary" style={{marginTop: 15 }} onClick={handleSubmit}>
        Guardar
      </Button>
    </Box>
    <Typography sx={{color: 'red', marginTop:2}}>{mensaje}</Typography>     
    </Box>
  );
};

export default Proveedor;
