import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
    <Typography component="h2" variant="h5" color="primary" gutterBottom sx={{marginBottom:2}}>
      Certificacion de Proveedores
    </Typography>
    <Typography sx={{marginBottom:6}}>
    Bienvenido al micrositio de SurCompliance de Certificación de Proveedores. En este site Ud. podrá encontrar los requisitos para certificar notarialmente las normas de proveedores de TENARIS, TERNIUM Y FINMA.
    <br />
    <br />

SurCompliance es la empresa que se encarga de evaluar y verificar el cumplimiento de la documentación solicitada para la certificación de las normas mencionadas. Estas normas deben firmarse ante Escribano Público. Asimismo, coordina y asigna el turno en las distintas escribanías del País y efectúa el seguimiento hasta la realización final del trámite de certificación por medio de los distintos Canales de Atención.
<br />
<br />

A fin de lograr la certificación de las normas, deben considerarse los siguientes aspectos:
<br />
<ul>          
  <li>Las Normas a Certificar</li>
  <li>La forma de Acreditar Personería</li>
  <li>La Documentación a Presentar que forma parte de su legajo de proveedor</li>
</ul>          
    </Typography>
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ flex: 1, borderRight: 1, borderColor: 'divider' }}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Normas a Certificar" {...a11yProps(0)} />
          <Tab label="Acreditación de Personería" {...a11yProps(1)} />
          <Tab label="Documentación a Presentar" {...a11yProps(2)} />
          <Tab label="Canales de Atención" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <Box sx={{ flex: 3 }}>
        <CustomTabPanel value={value} index={0}>
            <div>A continuaci&oacute;n dejamos a su disposicion las normas necesarias para la certificaci&oacute;n en Exiros.</div>
            <br />
            <div><a href="/normas/siderarcontratistas.pdf" target="_blank">Ternium: Contratistas</a></div><br/>
            <div><a href="/normas/TerminosyCondicionesTenaris_2023.pdf" target="_blank">Tenaris: Términos y Condiciones</a></div><br/>
            <div><a href="/normas/FINMA.pdf" target="_blank">FINMA: Términos y Condiciones Generales</a></div><br/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          1. Constancia de <b>Inscripción de A.F.I.P.</b>
          <br/>
          <br/>
          2. Constancia de <b>Inscripción en el impuesto a los Ingreso Brutos.</b>
          <br/>
          <br/>
          3. <b>Declaración Jurada de Cargas Sociales</b> junto con comprobantes de pago de los últimos tres ejercicios. (en caso de ser empleador).
          <br/>
          <br/>
          4. <b>Estatuto Social</b> (no aplica para personas humanas)
          <br/>
          <br/>
          5. <b>Último Balance</b> (no aplica para personas humanas)
          <br/>
          <br/>
          6. Inscripción en <b>Registros Especiales</b> (en caso de corresponder según actividad/empresa)
          <br/>
          <br/>
          7. <b>Acta o nota del Contratista</b>, dirigida a la Empresa, que acredite haber recibido la presente normativa y hallarse impuesto de su contenido, declarando su conformidad y aceptación expresa, sin reservas, de la totalidad de las cláusulas y condiciones previstas por la misma. En prueba de esa conformidad, el Contratista (a través del representante legal de la sociedad o persona autorizada, en su caso) deberá firmarla en todas sus hojas, juntamente con el acta o nota referida, en presencia de un funcionario de la Empresa, o bien obteniendo la certificación de la misma por escribano público. (exclusivo para firmantes norma Ternium). <a href='/normas/NotaConformidad.doc' target='_blank'>Ver modelo de Nota</a>
          <br/>
          <br/>
          8. Nota en carácter de <b>declaración jurada</b>, informando la <b>persona designada</b> para actuar en carácter de Representante de la Contratista, detallando sus datos personales, domicilio y teléfono, y firmada por ambos. En ningún caso el domicilio fiscal/comercial/social puede ser constituido en instalaciones o domicilios de la Empresa (exclusivo para firmantes norma Ternium). <a href='/normas/NotaRepresentante.doc' target='_blank'>Ver modelo de Nota</a>
          <br/>
          <br/>
          9. <b>Notas solicitadas en el apartado 6.1.3.</b> de la presente Norma en la forma allí establecida (exclusivo para firmantes norma Ternium). <a href='/normas/Nota6.1.3.doc' target='_blank'>Ver modelo de Nota</a>
          <br/>
          <br/>
          <b>La documentación a presentar debe dejarse en la escribanía en oportunidad de realizarse la firma, en formato impreso y/o en soporte digital (pen drive rotulado).</b>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
        <Typography>
            1.) <b>Personas Jurídicas:</b><br/>
            <br/>
            Si el firmante fuere:<br/>
            <br/>
            1.1)	Presidente (S.A.):<br/>
            Estatutos y sus modificaciones.<br/>
            Última acta de asamblea de elección de autoridades.<br/>
            Última acta de directorio de distribución de cargos.<br/>
            DNI original y fotocopia.<br/>
            <br/>
            1.2)	Socio Gerente (S.R.L.):<br/>
            Contrato social y sus modificaciones.<br/>
            Si la sociedad tiene libro de acta de reunión de socios y gerentes: el ultimo acta de elección de gerentes.<br/>
            DNI original y fotocopia.<br/>
            <br/>
            1.3)	Apoderado (Para todos los casos):<br/>
            Poder con facultades suficientes ( ej: firmar contratos, licitaciones, firmar propuestas, suscribir los contratos pertinentes y sus modificaciones)<br/>
            Estatutos y sus modificaciones.<br/>
            DNI original y fotocopia.<br/>
            Última acta de asamblea de elección de autoridades.<br/>
            Última acta de directorio de distribución de cargos.<br/>
            <br/>
            <br/>
            <u>En todos los casos es necesario el original y las fotocopias.</u><br/>
            <br/>
            <u>Los libros también en original y fotocopias.</u><br/>
            <br/>
            En caso de certificar norma en escribanía de capital federal: si los estatutos y/o poderes fueren otorgados en la pcia. de buenos aires o en otra jurisdicción que no fuere la capital federal, deberán estar legalizados los originales y las fotocopias debidamente certificadas por escribano y legalizadas en el colegio de la jurisdicción pertinente.<br/>
            <br/>
            En caso de certificar norma en escribanía de provincia de buenos aires: si los estatutos y/o poderes fueren otorgados en capital federal o en otra jurisdicción que no fuere la ciudad de buenos aires deberán estar legalizados los originales y las fotocopias debidamente certificadas por escribano y legalizadas en el colegio de la jurisdicción pertinente.<br/>
            <br/>
            <br/>
            2.) <b>Personas Físicas:</b><br/>
            <br/>
            2.1) Presentar DNI Original y fotocopia.<br/>
            <br/>
            <br/>
            <br/>
            3.) <b>Sociedades de Hecho:</b><br/>
            <br/>
            3.1) Presentar DNI Original y fotocopia de todos los componentes de la sociedad.<br/>
            <br/>
            3.2) Presentar estatuto o Instrumento donde surja la constitución de la Sociedad de Hecho.<br/>
            <br/>
            3.3.) Deberán concurrir todos los componentes de la Sociedad de Hecho a la Escribanía a firmar las normas correspondientes.<br/>
            <br/>
            <br/>
            4) <b>Empresas SAS</b><br/>
            <br/>
            4.1) Ídem 1 Personas Jurídicas
            <br/>
            <br/>
            <b>Aclaración: en caso de empresas SAS, toda la documentación es digital. Debe poseer estatuto, modificaciones y reformas (si las hubiere), y acta con la última designación de autoridades con mandato vigente. Se recomienda adicionalmente llevar la impresión de la Plancheta de Inscripción.</b>
            <br/>
            <br/>
            5) <b>ONGs</b><br/>
            <br/>
            5.1) Ídem 1 Personas Jurídicas
            <br/>
            <br/>
            <b>Aclaración: en caso de ONGs deben estar inscriptas y empadronadas en el organismo correspondiente (IGJ/DPPJ). Debe poseer estatuto/contrato constitutivo, modificaciones y reformas (si las hubiere), y acta con la última designación de autoridades con mandato vigente.</b>            
            </Typography>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          A continuación, se detallan los distintos canales de atención puestos a disposición de los proveedores con fin de agilizar la gestión de la certificación notarial de las normas:<br/>
          <br/>
          <u>Horario de atención:</u> Lunes a Viernes de 10:00 hs a 16:00 hs.
          <ul>
            <li><b>Teléfonos:</b> +54 11 4742-6432/7568 </li>
            <li><b>Email:</b> info@surcompliance.com.ar </li>
            <li><b>Guardia Telefónica:</b> <WhatsAppIcon sx={{ color: 'green', fontSize: 18 }} /> +54 9 11 3408-4546</li>
            <li><b>Oficinas:</b> Cosme Beccar 168 - San Isidro (CP 1642) - Provincia de Buenos Aires. </li>
          </ul>
          <br/>
          <u>Red de Escribanías:</u>
          <ul>          
            <li>Gobierno Autónomo de la Ciudad de Bs As (C.A.B.A.)</li>
            <li>Provincia de Buenos Aires (San Nicolas)</li>
            <li>Provincia de Buenos Aires (San Isidro)</li>
            <li>Provincia de Buenos Aires (Bahía Blanca)</li>
            <li>Provincia de Córdoba</li>
            <li>Provincia de Mendoza</li>
            <li>Provincia de Neuquén</li>
            <li>Provincia de Salta</li>
            <li>Provincia de San Luis</li>
          </ul>

          Durante el proceso de certificación el Proveedor podrá verificar el avance del trámite en el siguiente <a href='#/consultar'>link</a>        
        </CustomTabPanel>
      </Box>
    </Box>
    </Box>
  );
}
