import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Container, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Proveedor } from '../types/Proveedor';
import EstadoNormas from './EstadoNormas';
import { apiUrl } from '../config';
import { Documentacion } from '../types/Documentacion';
import { getCurrentUser } from '../session';
import { formatCuit } from '../utils';

const EstadoProveedor: React.FC = () => {
  const user = getCurrentUser();
  const { id_proveedor } = useParams();
  const [data, setData] = useState<(Proveedor & Documentacion) | undefined>({} as any);
  const [mensaje, setMensaje] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if(!id_proveedor) {
          console.error('Error: proveedor cannot be null.');
          return;
      }

      try {
        const body = new FormData();
        body.append("id_proveedor", id_proveedor);

        // Make a GET request when the component is mounted
        const response = await fetch(apiUrl + 'estado.php', {
            method: 'POST',
            body,
            credentials: 'include',
          });
    
        if (!response.ok) {
          navigate("/login");
        }

        const responseData = await response.json();

        // Update the component state with the fetched data
        setData(responseData);
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchData();
  }, []); // The empty dependency array ensures it runs only once.

  return (
    <Box>    
      { data ?
        <Box maxWidth="md" sx={{marginTop: 10}}>       
          <Box style={{display: 'flex', flexDirection: 'row' }}>
            <Typography component="h2" variant="h5" color="primary" gutterBottom>{data.nombre} ({formatCuit(data.cuit)})</Typography>
            {user?.type === 'ADMIN' ?
              <Link style={{marginLeft:'auto' }} to={`/proveedor/${id_proveedor}/documentacion`}><Button style={{marginLeft: 5 }} variant="contained" color="primary">Ingresar Documentación</Button></Link>
              : <span></span>}
            {user?.type === 'ADMIN' && data?.id_documentacion ?
              <Link to={`/proveedor/${id_proveedor}/documentacion/${data.id_documentacion}`}><Button style={{marginLeft: 5 }} variant="contained" color="secondary">Modificar</Button></Link>
              : <span></span>}

          </Box>
            <Typography variant="h6" sx={{marginTop: 4}} gutterBottom>Normas Certificadas:</Typography>
            <EstadoNormas data={data} />
            <table>
              <tr>
                <td colSpan={2}><Typography variant="h6" sx={{marginTop: 4}}gutterBottom>Datos Firmante:</Typography></td>
              </tr>
              <tr>	
                <td>Identificaci&oacute;n del Firmante</td>
                <td>{ data.firmante }</td>
              </tr>
              <tr>	
                <td>Cargo del Firmante</td>
                <td>{ data.cargofirmante }</td>
              </tr>
              <tr>	
                <td>Escriban&iacute;a</td>
                <td>{ data.escribania }</td>
              </tr>
              <tr>	
                <td>Observaciones</td>
                <td>{ data.observaciones }</td>
              </tr>
              <tr>
                <td colSpan={2}><Typography variant="h6" sx={{marginTop: 4}} gutterBottom>Documentación Principal:</Typography></td>
              </tr>
              <tr>	
                <td>Constancia de Inscripción AFIP</td>
                <td>{ data.iafip }</td>
              </tr>
               { data.tipopersona == "FISICA_MONOTRIBUTO" ? 
              <tr>	
                <td>Inscripción Monotributo</td>
                <td>{ data.imonotributo }</td>
              </tr> : 
              <tr>	
                <td>Inscripción en IVA</td>
                <td>{ data.iiva }</td>
              </tr>
              }
              <tr>	
                <td>Inscripción en Ganancias</td>
                <td>{ data.iganancias }</td>
              </tr>
              <tr>	
                <td>Inscripción en Seguridad Social</td>
                <td>{ data.iss }</td>
              </tr>
              <tr>	
                <td>Inscripción en Ingresos Brutos</td>
                <td>{ data.iib }</td>
              </tr>
              <tr>	
                <td>Otros Impuestos Nacionales</td>
                <td>{ data.otrosimpuestos }</td>
              </tr>
              <tr>	
                <td>Cargas Sociales (ultimos 3 ejercicios)</td>
                <td>{ data.cargassociales }</td>
              </tr>
              <tr>	
                <td>Dotaci&oacute;n de personal declarado</td>
                <td>{ data.dotacion }</td>
              </tr>
              { data.tipopersona == "JURIDICA" ? 
                <React.Fragment>                  
                  <tr>	
                    <td>Estatuto Social</td>
                    <td>{ data.estatutosocial }</td>
                  </tr>
                  <tr>	
                    <td>Entrego Balances</td>
                    <td>{ data.balances }</td>
                  </tr>
                  <tr>	
                    <td>Fecha de Cierre Ejercicio</td>
                    <td>{ data.fechacierre }</td>
                  </tr>
                  <tr>	
                    <td>Observaciones</td>
                    <td>{ data.observaciones2 }</td>
                  </tr>
                </React.Fragment>
                :<tr>	
                  <td>Observaciones</td>
                  <td>{ data.observaciones2 }</td>
                </tr>
              }              
            </table>
        </Box> 
        : 
        <Typography sx={{color: 'red', marginTop:2}}>{mensaje}</Typography>     
      }
    <Link to={'/proveedores'}><Button variant="outlined" sx={{marginTop:5}}>Volver</Button></Link>
    </Box>
  );
};

export default EstadoProveedor;
