import React, { useState, ChangeEvent, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from '@mui/material';

interface NormaFieldProps {
  label: string;
  value: string;
  defaultValue: string;
  onChange: (value: string, checked: boolean) => void;
}

const NormaField: React.FC<NormaFieldProps> = ({
  label,
  value,
  defaultValue,
  onChange,
}) => {
  const [checked, setChecked] = useState(value!=='No Aplica');
  const [textFieldValue, setTextFieldValue] = useState<string>(value);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      setTextFieldValue(defaultValue);
      onChange(defaultValue, true);
    } else {
      setTextFieldValue('No Aplica');
      onChange('No Aplica', false);
    }
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('handleTextFieldChange fired');
    const newValue = event.target.value;
    setTextFieldValue(newValue);
    onChange(newValue, checked);
  };

  return (
    <Box style={{display: 'flex', flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label={label}
        labelPlacement="start"
        sx={{ width: 1/2, marginRight:1 }}
      />
      <TextField
        value={textFieldValue}
        onChange={handleTextFieldChange}
        disabled={!checked}
        variant="outlined"
        fullWidth
        margin="normal"
      />
    </Box>
  );
};

export default NormaField;
