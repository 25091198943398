import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@mui/material';
import { apiUrl } from '../config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Documentacion as DocumentacionData } from '../types/Documentacion';
import { Proveedor as ProveedorData } from '../types/Proveedor';
import NormaField from './NormaField';

const Documentacion: React.FC = () => {
  const navigate = useNavigate();
  const { id_proveedor, id_documentacion } = useParams();
  const [proveedor, setProveedor] = useState<ProveedorData | undefined>(undefined);
  const [data, setDataI] = useState<DocumentacionData | undefined>(undefined);
  const [defaultValues, setDefaultValues] = useState<any>({});
  const [mensaje, setMensaje] = useState('');

  const setData = (v:any) => {
    console.log('settting data:', v);
    setDataI(v);
  }

  useEffect(() => {
    async function fetchProveedor() {
      try {
        if (!id_proveedor) {
          return;
        }

        const body = new FormData();
        body.append("id_proveedor", id_proveedor);

        // Make a GET request when the component is mounted
        const response = await fetch(apiUrl + 'estado.php', {
          method: 'POST',
          body,
          credentials: 'include',
        });

        if (!response.ok) {
          navigate("/login");
        }

        // Update the component state with the fetched data
        setProveedor(await response.json());
      } catch (error) {
        console.error('Error:', error);
      }
    }

    fetchProveedor();
  }, []); // The empty dependency array ensures it runs only once.

  useEffect(() => {
    async function fetchDocumentacion() {
      if (!proveedor) return;

      const defaults = {
        normasiderar: proveedor.Ternium.ultima,
        normasiderca: proveedor.Tenaris.ultima,
        normafinma: proveedor.FINMA.ultima,
        ccternium: 'FIRMADO',
        ncternium: 'FIRMADA',
        cctenaris: 'FIRMADO',
        nctenaris: 'FIRMADA',
      };  
      setDefaultValues(defaults);
      
      let currentData = {
        id_proveedor: proveedor.id_proveedor,
        ...defaults,
      } as DocumentacionData;
      
      if (id_documentacion) {
        try {
          // Make a GET request when the component is mounted
          const response = await fetch(apiUrl + 'documentacion.php?id=' + id_documentacion);
  
          if (!response.ok) {
            navigate("/login");
          }

          currentData = await response.json();
        } catch (error) {
          console.error('Error:', error);
        }
      }    

      setData(currentData);
    }
    fetchDocumentacion();
  }, [proveedor]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!data?.fechacertificacion || !data.firmante || !data.cargofirmante || !data.escribania) {
      setMensaje('Por favor complete los campos obligatorios (*)')
      return;
    }

    try {
      const response = await fetch(apiUrl + 'documentacion.php', {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(data),
        credentials: 'include',
      });

      console.log('sending data..', JSON.stringify(data));
      if (response.ok) {
        // Handle a successful login here, e.g., redirect or display a success message
        console.log('Request ok!');
        setMensaje('');
        navigate(`/estado/${id_proveedor}`);
      } else {
        // Handle login failure, e.g., display an error message
        console.error('Request failed');
        setMensaje('No se pudo encontrar la informacion solicitada.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setMensaje('Ocurrio un error, por favor consulte telefonicamente.')
    }
  };

  return (
    <Box>
    { proveedor && data ? 
      <Box maxWidth="md">
        <Typography component="h2" variant="h5" color="primary" gutterBottom>
          Documentación del Proveedor: {proveedor.nombre}
        </Typography>
        <form>
          <Box style={{display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h6" sx={{width: 1/3, marginTop: 4}} gutterBottom>
              Fecha de Certificación: *
            </Typography>
            <TextField
                variant="outlined"
                type="date"
                margin="normal"
                value={data.fechacertificacion || ''}
                onChange={(e) => setData({ ...data, 'fechacertificacion': e.target.value})}
                sx={{marginLeft: 2}}
              />
          </Box>
          <Typography variant="h6" sx={{marginTop: 4}}gutterBottom>Normas Certificadas:</Typography>
          <NormaField 
            label="Norma Ternium/Siderar"
            value={data.normasiderar || ''}
            defaultValue={defaultValues.normasiderar}
            onChange={(v) => setData({ ...data, 'normasiderar': v})}
          />
          <NormaField 
            label="Conv. de Confidencialidad Ternium"
            value={data.ccternium || ''}
            defaultValue={defaultValues.ccternium}
            onChange={(v) => setData({ ...data, 'ccternium': v})}
          />
          <NormaField 
            label="Notas Complementarias Ternium"
            value={data.ncternium || ''}
            defaultValue={defaultValues.ncternium}
            onChange={(v) => setData({ ...data, 'ncternium': v})}
          />
          <NormaField 
            label="Norma Tenaris/Siderca"
            value={data.normasiderca || ''}
            defaultValue={defaultValues.normasiderca}
            onChange={(v) => setData({ ...data, 'normasiderca': v})}
          />
          <NormaField 
            label="Conv. de Confidencialidad Tenaris"
            value={data.cctenaris || ''}
            defaultValue={defaultValues.cctenaris}
            onChange={(v) => setData({ ...data, 'cctenaris': v})}
          />
          <NormaField 
            label="Notas Complementarias Tenaris"
            value={data.nctenaris || ''}
            defaultValue={defaultValues.nctenaris}
            onChange={(v) => setData({ ...data, 'nctenaris': v})}
          />
          <NormaField 
            label="Norma FINMA"
            value={data.normafinma || ''}
            defaultValue={defaultValues.normafinma}
            onChange={(v) => setData({ ...data, 'normafinma': v})}
          />
          
          <Typography variant="h6" sx={{marginTop: 4}}gutterBottom>Datos Firmante:</Typography>

          <TextField
            label="Identificaci&oacute;n del Firmante *"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.firmante || ''}
            onChange={(e) => setData({ ...data, 'firmante': e.target.value})}
          />
          <TextField
            label="Cargo del Firmante *"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.cargofirmante || ''}
            onChange={(e) => setData({ ...data, 'cargofirmante': e.target.value})}
          />
          <TextField
            label="Email del Firmante"
            variant="outlined"
            type="email"
            fullWidth
            margin="normal"
            value={data.mailfirmante || ''}
            onChange={(e) => setData({ ...data, 'mailfirmante': e.target.value})}
          />
          <TextField
            label="Escribanía *"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.escribania || ''}
            onChange={(e) => setData({ ...data, 'escribania': e.target.value})}
          />
          <TextField
            label="Observaciones"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.observaciones || ''}
            onChange={(e) => setData({ ...data, 'observaciones': e.target.value})}
            minRows={3}
            multiline
          />

          <Typography variant="h6" sx={{marginTop: 4}} gutterBottom>Documentación Principal:</Typography>
          <Box style={{display: 'flex', flexDirection: 'row' }}>
            <FormControl fullWidth>
              <FormLabel>Constancia de Inscripción AFIP</FormLabel>
              <RadioGroup
                row
                value={data.iafip}
                onChange={(e) => setData({ ...data, 'iafip': e.target.value})}
              >
                <FormControlLabel value="SI" control={<Radio />} label="Si" />
                <FormControlLabel value="NO" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            { proveedor.tipopersona == "FISICA_MONOTRIBUTO" ? 
              <FormControl fullWidth>
                <FormLabel>Inscripción Monotributo</FormLabel>
                <RadioGroup
                  row
                  value={data.imonotributo}
                  onChange={(e) => setData({ ...data, 'imonotributo': e.target.value})}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="Si" />
                  <FormControlLabel value="NO" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              : 
              <FormControl fullWidth>
                <FormLabel>Inscripción en IVA</FormLabel>
                <RadioGroup
                  row
                  value={data.iiva}
                  onChange={(e) => setData({ ...data, 'iiva': e.target.value})}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="Si" />
                  <FormControlLabel value="NO" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
              }
              <FormControl fullWidth>
                <FormLabel>Inscripción en Ganancias</FormLabel>
                <RadioGroup
                  row
                  value={data.iganancias}
                  onChange={(e) => setData({ ...data, 'iganancias': e.target.value})}
                >
                  <FormControlLabel value="SI" control={<Radio />} label="Si" />
                  <FormControlLabel value="NO" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
          </Box>
          <Box style={{display: 'flex', flexDirection: 'row' }}>
            <FormControl fullWidth>
              <FormLabel>Inscripción en Ingresos Brutos</FormLabel>
              <RadioGroup
                row
                value={data.iib}
                onChange={(e) => setData({ ...data, 'iib': e.target.value})}
              >
                <FormControlLabel value="SI" control={<Radio />} label="Si" />
                <FormControlLabel value="NO" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Inscripción en Seguridad Social</FormLabel>
              <RadioGroup
                row
                value={data.iss}
                onChange={(e) => setData({ ...data, 'iss': e.target.value})}
              >
                <FormControlLabel value="SI" control={<Radio />} label="Si" />
                <FormControlLabel value="NO" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            
            <FormControl fullWidth>
              <FormLabel>Cargas Sociales (ultimos 3 ejercicios)</FormLabel>
              <RadioGroup
                row
                value={data.cargassociales}
                onChange={(e) => setData({ ...data, 'cargassociales': e.target.value})}
              >
                <FormControlLabel value="SI" control={<Radio />} label="Si" />
                <FormControlLabel value="NO" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
          { proveedor.tipopersona == "JURIDICA" ? 
            <React.Fragment> 
              <Box style={{display: 'flex', flexDirection: 'row' }}>
                <FormControl fullWidth>
                  <FormLabel>Estatuto Social</FormLabel>
                  <RadioGroup
                    row
                    value={data.estatutosocial}
                    onChange={(e) => setData({ ...data, 'estatutosocial': e.target.value})}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel>Entregó Balances</FormLabel>
                  <RadioGroup
                    row
                    value={data.balances}
                    onChange={(e) => setData({ ...data, 'balances': e.target.value})}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                
                <FormControl fullWidth>
                  <FormLabel>Inscripción en Registros Especiales</FormLabel>
                  <RadioGroup
                    row
                    value={data.iregistrosespeciales}
                    onChange={(e) => setData({ ...data, 'iregistrosespeciales': e.target.value})}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="Si" />
                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box style={{display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{width: 1/3, marginTop: 4}} gutterBottom>
                  Fecha de Cierre Ejercicio: 
                </Typography>
                <TextField
                    variant="outlined"
                    type="date"
                    margin="normal"
                    value={data.fechacierre || ''}
                    onChange={(e) => setData({ ...data, 'fechacierre': e.target.value})}
                  />
              </Box>
            </React.Fragment>
            :
              <div></div>
          }
          <TextField
            label="Otros Impuestos Nacionales"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.otrosimpuestos || ''}
            onChange={(e) => setData({ ...data, 'otrosimpuestos': e.target.value})}
          />
          <TextField
            label="Dotaci&oacute;n de personal declarado"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.dotacion || ''}
            onChange={(e) => setData({ ...data, 'dotacion': e.target.value})}
          />
          <TextField
            label="Observaciones"
            variant="outlined"
            type="text"
            fullWidth
            margin="normal"
            value={data.observaciones2 || ''}
            onChange={(e) => setData({ ...data, 'observaciones2': e.target.value})}
            minRows={3}
            multiline
          />
          </form> 
        <Link to={`/estado/${id_proveedor}`}><Button variant="outlined" style={{marginTop: 15, marginRight: 5 }}>Volver</Button></Link>
        <Button variant="contained" color="primary" style={{marginTop: 15 }} onClick={handleSubmit}>
          Guardar
        </Button>
      </Box>
    : 
      <Box sx={{ display: 'flex', flexDirection: 'column',  alignItems: 'center' }}>
        <CircularProgress size="5rem"/>
      </Box>
    }
    <Typography sx={{color: 'red', marginTop:2}}>{mensaje}</Typography>     
    </Box>
  );
};

export default Documentacion;
